import { useOktaAuth } from '@okta/okta-react'
import { useEffect, useRef, useState, type ReactNode } from 'react'
import { useLocation } from 'wouter'
import { PATH_LOGIN } from '../constants/paths.ts'
import { toRelativeUrl } from '@okta/okta-auth-js'

/* This component is only used for testing OKTA purposes
 and shouldn't be used elsewhere - unless we decide to 
 primarily rely on OKTA token management in addition to sign-in
 */
export const OktaSecuredRoute = ({ children }: { children: ReactNode }) => {
  const { authState, oktaAuth, _onAuthRequired } = useOktaAuth()
  const setLocation = useLocation()[1]

  const pendingLogin = useRef(false)
  const [handleLoginError, setHandleLoginError] = useState<Error | null>(null)

  useEffect(() => {
    const handleLogin = async () => {
      if (pendingLogin.current) {
        return
      }

      pendingLogin.current = true

      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      )
      oktaAuth.setOriginalUri(originalUri)
      const onAuthRequiredFn = _onAuthRequired
      if (onAuthRequiredFn) {
        await onAuthRequiredFn(oktaAuth)
      } else {
        setLocation(`${window.location.origin}${PATH_LOGIN}`)
      }
    }
    if (!authState) {
      return
    }
    if (authState.isAuthenticated) {
      pendingLogin.current = false
      return
    }
    if (authState && !authState.isAuthenticated) {
      handleLogin().catch((err) => {
        setHandleLoginError(err)
      })
    }
  }, [authState, oktaAuth, setLocation, _onAuthRequired])

  if (handleLoginError) {
    return <div>{JSON.stringify(handleLoginError)}</div>
  }

  if (!authState || !authState.isAuthenticated) {
    return null
  }

  return children
}
