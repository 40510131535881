import { storeAccessToken } from '@mulliganfunding/api-micro-auth'
import type { AuthnTransaction, OktaAuth } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { useLocation } from 'wouter'
import { BrandedContentPanel } from '../../../components/branded-content-panel/branded-content-panel.tsx'
import { OktaSignIn } from '../../../components/okta-sign-in.tsx'
import { TwoColumnPage } from '../../../components/two-column-page/two-column-page.tsx'
import {
  PATH_LOGIN_MAGIC_LINK,
  PATH_START_APPLICATION,
} from '../../../constants/paths.ts'

import envelopeIcon from '../../../assets/imgs/link-email-icon.svg'
import './login.css'

export const Login = () => {
  const { authState } = useOktaAuth()
  const setLocation = useLocation()[1]

  const onSuccess = (
    oktaAuth: OktaAuth,
    transaction: AuthnTransaction,
    mfAccessToken: string,
  ) => {
    storeAccessToken(mfAccessToken)
    /* TODO: If you need to see what is going on before redirect - uncomment this section and
     comment out the oktaAuth?.signInWithRedirect line
    */
    // console.log({transaction, oktaAuth, mfAccessToken})
    // setTimeout(() => {
    //   oktaAuth?.signInWithRedirect({ sessionToken: transaction.sessionToken })
    // }, 20000)
    oktaAuth?.signInWithRedirect({ sessionToken: transaction.sessionToken })
  }

  const onError = (err: { message: string; error: any }) => {
    console.log('Sign in error:', err.message, err.error)
  }

  if (!authState) {
    return <div>Loading ... </div>
  }

  return (
    <TwoColumnPage>
      <BrandedContentPanel title="Welcome Back">
        <div className="explanatory-text typog-body-large">
          {`Sign in to access your Mulligan Funding account.`}
        </div>
        <OktaSignIn onSuccess={onSuccess} onError={onError} />
        <button
          className="magic-link-button"
          onClick={() => {
            const magicLinkPath = `${window.location.origin}${PATH_LOGIN_MAGIC_LINK}`
            console.log(`Going to Magic Link Login screen`)
            setLocation(magicLinkPath)
          }}
        >
          <img
            className="envelope-icon"
            src={envelopeIcon}
            alt="blue envelope icon"
          />
          <span>Email Only Sign In</span>
        </button>
        <div className="alternate-nav">
          <span>Not a customer?</span>
          <div
            className="link"
            onClick={() => {
              const directAppPath = `${window.location.origin}/${PATH_START_APPLICATION}`
              console.log(`Going to Start Application screen`)
              window.location.assign(directAppPath)
            }}
          >
            Apply For a Loan
          </div>
        </div>
      </BrandedContentPanel>
    </TwoColumnPage>
  )
}
