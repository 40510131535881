// these paths are outside of customer-core so they don't use the URL_PREFIX
export const PATH_DASHBOARD = 'ui/dashboard/'
export const PATH_DOC_CHECKOUT_START = `${PATH_DASHBOARD}doc-checkout/loan-details/`
export const PATH_DIRECT_APP = 'ui/direct-app/'
export const PATH_START_APPLICATION = `${PATH_DIRECT_APP}start-application`
export const PATH_DIRECT_APP_VERIFY_EMAIL = `${PATH_DIRECT_APP}email-verification/`

export const URL_PREFIX = '/'
export const PATH_ABOUT = `${URL_PREFIX}about`
export const PATH_CALLBACK_HANDLER = `${URL_PREFIX}auth/handler`
export const PATH_DOC_CHECKOUT_FORWARDER = `${URL_PREFIX}doc-checkout/loan/:loanId/start`
export const PATH_DIRECT_APP_VERIFY_EMAIL_FORWARDER = `${URL_PREFIX}app/email-verification/:token`
export const PATH_FORGOT_PASSWORD = `${URL_PREFIX}auth/forgot-password`
export const PATH_LOGIN = `${URL_PREFIX}auth/login`
export const PATH_LOGIN_CALLBACK = `${URL_PREFIX}auth/callback`
export const PATH_LOGIN_MAGIC_LINK = `${URL_PREFIX}auth/magic-link`
export const PATH_MAGIC_LINK_LANDING = `${URL_PREFIX}auth/magic-link-landing/:token`
export const PATH_NEXT_STEP_PREFERENCE = `${URL_PREFIX}next-step-preference`
export const PATH_PASSWORD_RESET = `${URL_PREFIX}auth/password-reset/:token`
export const USER_REGISTRATION_PATH = `${URL_PREFIX}user-registration/:token`
