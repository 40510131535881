import type { ReactNode } from 'react'

import tiltedPhoneImage from '../../assets/imgs/sign-in-portal-preview.png'

import './two-column-page.css'
import { FOOTER_STRING_COPYRIGHT } from '../../constants/index.ts'

const tiltedPhone: ReactNode = (
  <img
    src={tiltedPhoneImage}
    className="tilted-phone"
    alt={'phone with picture of Mulligan Funding customer dashboard'}
  />
)

export const TwoColumnPage = ({
  children,
  sizzleChildren = tiltedPhone,
}: {
  children: ReactNode
  sizzleChildren?: ReactNode
}) => {
  return (
    <div className="two-column-page">
      <div className="content">
        <div className="main-content">{children}</div>
        <div className="footer-content typog-body">
          {FOOTER_STRING_COPYRIGHT}
        </div>
      </div>
      <div className="sizzle">{sizzleChildren}</div>
    </div>
  )
}
