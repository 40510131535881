import { z } from 'zod'

export const newPasswordSchema = z
  .object({
    password: z.string({ message: 'Password is required.' }),
    confirmPassword: z.string({ message: 'Please confirm your password.' }),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (password.length < 8) {
      ctx.addIssue({
        code: 'custom',
        message: 'length',
        path: ['password'],
      })
    }
    if (!/[A-Z]/.test(password) || !/[a-z]/.test(password)) {
      ctx.addIssue({
        code: 'custom',
        message: 'case',
        path: ['password'],
      })
    }
    if (!/[0-9]/.test(password) && !/[!@#$%^&*()]/.test(password)) {
      ctx.addIssue({
        code: 'custom',
        message: 'number',
        path: ['password'],
      })
    }
    if (password.length < 8) {
      ctx.addIssue({
        code: 'custom',
        message: 'length',
        path: ['password'],
      })
    }
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'match',
        path: ['confirmPassword'],
      })
    }
  })
