import * as Sentry from '@sentry/react'
import {
  SENTRY_CUSTOMER_PORTAL_APP_ID,
  MULLIGAN_FUNDING_SENTRY_COMPANY_ID,
} from '../constants/strings'
import { URL_PREFIX } from '../constants/paths'

export const getRunningEnv: () => 'local' | 'dev' | 'stg' | 'prod' = () => {
  const urlParts = window.location.origin.split('.')
  const prefix = urlParts[0].split('://')[1]
  if (prefix === 'local') {
    return 'local'
  } else {
    return urlParts[1] as 'dev' | 'stg' | 'prod'
  }
}

const allowUrls = [
  // `https://local.dev.mulligancloud.com/`,
  `https://${URL_PREFIX}.dev.mulligancloud.com/`,
  `https://${URL_PREFIX}.stg.mulligancloud.com/`,
  `https://${URL_PREFIX}.prod.mulligancloud.com/`,
]
export const SentryInit = () => {
  Sentry.init({
    environment: getRunningEnv(),
    integrations: [new Sentry.BrowserTracing()],
    allowUrls,
    dsn: `https://${MULLIGAN_FUNDING_SENTRY_COMPANY_ID}.ingest.sentry.io/${SENTRY_CUSTOMER_PORTAL_APP_ID}`,

    // Set tracesSampleRate to 1.0 to capture 100%
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}
