import { AuthSdkError, AuthState, OktaAuth } from '@okta/okta-auth-js'
import { OktaContext } from '@okta/okta-react'
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext'
import React, { useEffect, useRef, useState } from 'react'
import { ErrorWrapper } from './error-wrapper.tsx'

export const OktaContextWrapper = ({
  oktaAuth,
  restoreOriginalUri,
  children,
}: {
  oktaAuth: OktaAuth
  restoreOriginalUri?: RestoreOriginalUriFunction
  children?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>) => {
  const [authState, setAuthState] = useState(() => {
    if (!oktaAuth) {
      return null
    }
    return oktaAuth.authStateManager.getAuthState()
  })
  const authStateRef = useRef(authState)
  const oktaAuthRef = useRef(oktaAuth) // This ref is only used once on origianl mount so no sync'ing useEffect is needed
  const restoreOriginalUriRef = useRef(restoreOriginalUri) // This ref is only used once on origianl mount so no sync'ing useEffect is needed

  useEffect(() => {
    authStateRef.current = authState
  }, [authState])

  useEffect(() => {
    if (!oktaAuthRef.current) {
      return
    }

    if (restoreOriginalUriRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      oktaAuthRef.current.options.restoreOriginalUri = (async (
        oktaAuth: unknown,
        originalUri: string,
      ) => {
        return (restoreOriginalUriRef.current as RestoreOriginalUriFunction)(
          oktaAuth as OktaAuth,
          originalUri,
        )
      }) as (oktaAuth: OktaAuth, originalUri?: string) => Promise<void>
    } else {
      console.warn(
        'OktaContextWrapper not provided with restoreOriginalUri - falling back to config provided value',
      )
    }
  }, []) // empty array, only check on component mount

  useEffect(() => {
    if (!oktaAuth) {
      return
    }

    // Update Security provider with latest authState
    const currentAuthState = oktaAuth.authStateManager.getAuthState()
    if (currentAuthState !== authStateRef.current) {
      setAuthState(currentAuthState)
    }
    const handler = (authState: AuthState) => {
      setAuthState(authState)
    }
    oktaAuth.authStateManager.subscribe(handler)

    // Trigger an initial change event to make sure authState is latest
    oktaAuth.start()

    return () => {
      oktaAuth.authStateManager.unsubscribe(handler)
    }
  }, [oktaAuth])

  if (!oktaAuth) {
    const err = new AuthSdkError(
      'No oktaAuth instance passed to Security Component.',
    )
    return <ErrorWrapper error={err} />
  }

  return (
    <OktaContext.Provider
      value={{
        oktaAuth,
        authState,
      }}
    >
      {children}
    </OktaContext.Provider>
  )
}
