import type { SafeParseError, ZodIssue, ZodSchema } from 'zod'
import type { SchemaErrorObject } from '../models/schema-error-object.ts'

export const convertZodSafeParseToSchemaErrorObject = (
  schema: ZodSchema,
  validationResponse: SafeParseError<ZodSchema>,
) => {
  const errorList = validationResponse.error.issues.map((issue: ZodIssue) => {
    return {
      fieldList: issue.path,
      message: issue.message,
    }
  })
  const schemaErrorObject: SchemaErrorObject<ZodSchema> = {}
  errorList.forEach((error) => {
    error.fieldList.forEach((field) => {
      const errorObjectValue = Object.hasOwn(schemaErrorObject, field)
        ? (schemaErrorObject[field] as string[])
        : []
      errorObjectValue.push(error.message)
      schemaErrorObject[field] = errorObjectValue
    })
  })
  return schemaErrorObject
}
