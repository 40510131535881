import React from 'react'
import * as packageInfo from '../../../package.json'
import './about.css'
const { name, version } = packageInfo
export const About: React.FC = () => {
  return (
    <div>
      <div className="about-customer-core-title typog-h1-extra-large">
        Customer Core
      </div>
      <div className="single-line">
        <label className="about-text typog-body-small" htmlFor="appName">
          {`Name:`}
        </label>
        <input
          className="about-input typog-body"
          type="text"
          readOnly={true}
          id="appName"
          value={name}
        />
      </div>
      <div className="single-line">
        <label className="about-text typog-body-small" htmlFor="appVersion">
          {`Version:  `}
        </label>
        <input
          className="about-input"
          type="text"
          readOnly={true}
          id="appVersion"
          value={version}
        />
      </div>
    </div>
  )
}
