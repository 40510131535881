import { useOktaAuth } from '@okta/okta-react'
import { useEffect, useState, type ReactNode } from 'react'
import { ErrorWrapper } from '../../../components/error-wrapper.tsx'

/* ProcessLogin is inspired by the LoginCallback included in the @okta/okta-react library
This is meant to be a simple redirect accepting landing point.
The props were removed and streamlined, but the purpose is the same. If you are confused
reference the LoginCallback here: https://github.com/okta/okta-react/blob/master/src/LoginCallback.tsx
*/

let handledRedirect = false

export const ProcessLogin = ({
  loadingElement,
}: {
  loadingElement?: ReactNode
}) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [callbackError, setCallbackError] = useState(null)

  useEffect(() => {
    // OKTA-635977: Prevents multiple calls of handleLoginRedirect() in React18 StrictMode
    // Top-level variable solution follows: https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
    if (!handledRedirect) {
      oktaAuth.handleLoginRedirect().catch((e) => {
        setCallbackError(e)
      })
      handledRedirect = true
    }
  }, [authState, oktaAuth])

  const authError = authState?.error
  const displayError = callbackError || authError
  return !displayError ? (
    !loadingElement ? null : (
      loadingElement
    )
  ) : (
    <ErrorWrapper error={displayError} />
  )
}
