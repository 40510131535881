import type { ReactNode } from 'react'
import mfLogo from '../../assets/imgs/MF_HorizontalStacked_RGB.png'
import './branded-content-panel.css'

export const BrandedContentPanel = ({
  children,
  title,
}: {
  children: ReactNode
  title?: string
}) => {
  return (
    <div className="branded-content-panel">
      <div className="logo-wrapper">
        <img
          src={mfLogo}
          className="logo"
          alt={'Mulligan Funding M and company name'}
          onClick={() => {
            window.location.assign('https://www.mulliganfunding.com')
          }}
        />
      </div>
      {!title ? null : (
        <div className="typog-h1-extra-large content-title">{title}</div>
      )}
      {children}
    </div>
  )
}
