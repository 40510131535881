// This is the current OKTA Classic Engine credentials
const OKTA_BASE_URL = 'https://login.mulliganfunding.com'

export const OKTA_SCOPES = ['openid', 'profile', 'email']

export const OKTA_OIDC_CONFIG = {
  baseUrl: OKTA_BASE_URL,
  clientId: '0oa36d25qus47VJHm357',
  issuer: `${OKTA_BASE_URL}/oauth2/aus2zr8w34lrSHkiK357`,
  redirectUri: window.location.origin + '/auth/callback',
  useClassic: true,
  authParams: {
    issuer: `${OKTA_BASE_URL}/oauth2/aus2zr8w34lrSHkiK357`,
  },
  scopes: OKTA_SCOPES,
}

// This is the OKTA Identity Engine Sandbox credentials
const OKTA_BASE_URL_DEV = 'https://mulliganfundingapp.oktapreview.com'

export const OKTA_OIDC_CONFIG_DEV = {
  baseUrl: OKTA_BASE_URL_DEV,
  clientId: '0oak3vziw7Ltl4hZD1d7',
  issuer: `${OKTA_BASE_URL_DEV}/oauth2/aus2io5fy0v9YHDRw1d7`,
  redirectUri: window.location.origin + '/auth/callback', // See redirect provided to OKTA Context Wrapper
  scopes: OKTA_SCOPES,
}
