import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import type { CustomUserClaims, UserClaims } from '@okta/okta-auth-js'

export const CallbackHandler = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [userInfo, setUserInfo] = useState<UserClaims<CustomUserClaims> | null>(
    null,
  )

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null)
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [authState, oktaAuth]) // Update if authState changes

  if (!userInfo) {
    return (
      <div>
        <p>Fetching user info ...</p>
      </div>
    )
  }

  return (
    <div>
      <div>
        <p id="welcome">Welcome, &nbsp;{userInfo.name}!</p>
        <p>
          You have successfully authenticated against your Okta org, and have
          been redirected back to this application.
        </p>
      </div>
    </div>
  )
}
