import { ToastStack } from '@mulliganfunding/fe-toast-stack'
import { OktaAuth } from '@okta/okta-auth-js'
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext.js'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'wouter'
import { OktaContextWrapper } from '../components/okta-context-wrapper.js'
import { OktaSecuredRoute } from '../components/okta-secured-route.js'
import {
  PATH_ABOUT,
  PATH_CALLBACK_HANDLER,
  PATH_DASHBOARD,
  PATH_DIRECT_APP_VERIFY_EMAIL_FORWARDER,
  PATH_DOC_CHECKOUT_FORWARDER,
  PATH_FORGOT_PASSWORD,
  PATH_LOGIN,
  PATH_LOGIN_CALLBACK,
  PATH_LOGIN_MAGIC_LINK,
  PATH_MAGIC_LINK_LANDING,
  PATH_NEXT_STEP_PREFERENCE,
  PATH_PASSWORD_RESET,
  TOAST_DURATION,
  USER_REGISTRATION_PATH,
} from '../constants'
import { About } from '../routes'
import { Login } from '../routes/auth/login/login.js'
import { ProcessLogin } from '../routes/auth/login/process-login.js'
import { CallbackHandler } from '../routes/auth/callback-handler/callback-handler.js'
import { SentryInit } from './sentry-init'
import { getOktaConfigObject } from '../utils/get-okta-config-object.js'

import './app.css'

const DirectAppEmailVerificationForwarder = lazy(
  () => import('../routes/direct-app/email-verification-forwarder.js'),
)

const DocCheckoutForwarder = lazy(
  () => import('../routes/doc-checkout/doc-checkout-forwarder.js'),
)
const ForgotPassword = lazy(
  () => import('../routes/auth/password/forgot-password.js'),
)
const MagicLinkLanding = lazy(
  () => import('../routes/auth/magic-link-landing/magic-link-landing.js'),
)
const MagicLinkLogin = lazy(
  () => import('../routes/auth/magic-link-login/magic-link-login.js'),
)
const NextStepPreference = lazy(
  () => import('../routes/next-step-preference/next-step-preference.js'),
)
const PasswordReset = lazy(
  () => import('../routes/auth/password/password-reset.js'),
)

const config = getOktaConfigObject()
const oktaAuth = new OktaAuth(config)

SentryInit()

export const App = () => {
  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _oktaAuth,
    originalUri,
  ) => {
    console.log(`calling restoreOriginalUri with :`, originalUri)
    window.location.assign(
      originalUri || `${window.location.origin}/${PATH_DASHBOARD}`,
    )
  }
  const UserRegistration = lazy(
    () => import('../routes/user-registration/user-registration.js'),
  )

  return (
    <OktaContextWrapper
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <ToastStack duration={TOAST_DURATION} />
      <Switch>
        <Route path={PATH_ABOUT}>
          <About />
        </Route>
        <Route path={PATH_LOGIN}>
          <Login />
        </Route>
        <Route path={PATH_LOGIN_CALLBACK}>
          <ProcessLogin />
        </Route>
        <Route path={PATH_FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>
        <Route path={PATH_LOGIN_MAGIC_LINK}>
          <Suspense fallback={<div>Loading...</div>}>
            <MagicLinkLogin />
          </Suspense>
        </Route>
        <Route path={PATH_MAGIC_LINK_LANDING}>
          <Suspense fallback={<div>Loading...</div>}>
            <MagicLinkLanding />
          </Suspense>
        </Route>
        <Route path={PATH_NEXT_STEP_PREFERENCE}>
          <Suspense fallback={<div>Loading...</div>}>
            <NextStepPreference />
          </Suspense>
        </Route>
        <Route path={PATH_PASSWORD_RESET}>
          <PasswordReset />
        </Route>
        <Route path={USER_REGISTRATION_PATH}>
          <Suspense fallback={<div>Loading...</div>}>
            <UserRegistration />
          </Suspense>
        </Route>
        <Route path={PATH_DIRECT_APP_VERIFY_EMAIL_FORWARDER}>
          <DirectAppEmailVerificationForwarder />
        </Route>
        <Route path={PATH_DOC_CHECKOUT_FORWARDER}>
          <OktaSecuredRoute>
            <DocCheckoutForwarder />
          </OktaSecuredRoute>
        </Route>
        {/* This path is only here for testing purposes */}
        <Route path={PATH_CALLBACK_HANDLER}>
          <OktaSecuredRoute>
            <CallbackHandler />
          </OktaSecuredRoute>
        </Route>
      </Switch>
    </OktaContextWrapper>
  )
}
