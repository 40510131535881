import * as React from 'react'

/* This Error component is inspired by the OktaError found here:
https://github.com/okta/okta-react/blob/master/src/OktaError.tsx
*/

export const ErrorWrapper = ({ error }: { error: Error }) => {
  const properError = error.name && error.message
  return (
    <p>
      {properError
        ? `${error.name} ${error.message}`
        : `Error: ${error.toString()}`}
    </p>
  )
}
