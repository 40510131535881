import { microAuthApi } from '@mulliganfunding/api-micro-auth'
import { notifyError, toastMapState } from '@mulliganfunding/fe-toast-stack'
import { OktaAuth, type AuthnTransaction } from '@okta/okta-auth-js'
import { OktaContext, withOktaAuth } from '@okta/okta-react'
import type { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext'
import { useSetAtom } from 'jotai'
import React, {
  useActionState,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { z, type SafeParseError, type ZodSchema } from 'zod'
import showPasswordIcon from '../assets/imgs/show-icon.svg'
import { OKTA_SCOPES } from '../constants/okta.ts'
import { convertZodSafeParseToSchemaErrorObject } from '../utils/convert-zod-safe-parse-to-error-object.ts'
import { PATH_FORGOT_PASSWORD } from '../constants/paths.ts'
import { useLocation } from 'wouter'

export interface OktaSignInProps extends IOktaContext {
  onSuccess: (
    oktaAuth: OktaAuth,
    transaction: AuthnTransaction,
    mfAccessToken: string,
  ) => void
  onError: ({ message, error }: { message: string; error: any }) => void
}

const signInFormSchema = z.object({
  email: z
    .string({ message: 'Email is required.' })
    .email({ message: 'Invalid email address.' }),
  password: z
    .string({ message: 'Password is required.' })
    .min(8, { message: 'Password must be at least 8 characters.' }),
})

export const OktaSignIn = withOktaAuth(
  ({ onSuccess, onError }: OktaSignInProps) => {
    const oktaContext = useContext(OktaContext)
    const dispatch = useSetAtom(toastMapState)
    const setLocation = useLocation()[1]
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

    const signIn = useCallback(
      async (prevState: any, formData: FormData) => {
        const formDataObject = Object.fromEntries(formData)
        const validatedSignInForm = signInFormSchema.safeParse(formDataObject)
        if (validatedSignInForm.success) {
          try {
            if (oktaContext?.oktaAuth) {
              const oktaAuth = oktaContext.oktaAuth
              const transaction = await oktaAuth.signInWithCredentials({
                username: formDataObject.email as string,
                password: formDataObject.password as string,
              })

              /* TODO: if you want to inspect the transaction comment out the following block */
              // console.log(`okta-sign-in transaction:`, {transaction})
              if (transaction.status === 'SUCCESS') {
                window.localStorage.setItem(
                  'mf-profile',
                  JSON.stringify(transaction?.user?.profile ?? ''),
                )
                const tokens = await oktaAuth.token.getWithoutPrompt({
                  sessionToken: transaction.sessionToken,
                  responseType: ['id_token', 'token'],
                  scopes: OKTA_SCOPES,
                })

                /* TODO: if you want to inspect the tokens comment out the following block */
                // console.log(`okta-sign-in tokens:`, {tokens})
                const idToken = tokens.tokens.idToken?.idToken
                if (!idToken) {
                  throw new Error(
                    `Sign in success, but error obtaining idTokne: `,
                    {
                      cause: tokens,
                    },
                  )
                }
                const mfAccessToken = await microAuthApi.tokenExchange(idToken)
                if (mfAccessToken.isError) {
                  throw new Error(
                    `Sign in success, idToken obtained, but failure to exchange token with micro-auth`,
                    {
                      cause: mfAccessToken,
                    },
                  )
                }
                console.log(
                  `OKTA idToken exchanged successfully for micro-auth token`,
                )
                onSuccess(
                  oktaAuth,
                  transaction,
                  mfAccessToken.data.access_token,
                )
              } else {
                throw new Error('Could not sign in: ' + transaction.status)
              }
            }
          } catch (error) {
            const message = `Sign-in error: ${error}`
            console.warn(message)
            if (onError) {
              onError({
                message,
                error,
              })
            }
          }
          return {}
        } else {
          const schemaErrorObject = convertZodSafeParseToSchemaErrorObject(
            signInFormSchema,
            validatedSignInForm as SafeParseError<ZodSchema>,
          )
          Object.values(schemaErrorObject)
            .flat()
            .forEach((error) => {
              dispatch(
                notifyError({
                  message: error as string,
                }),
              )
            })
          return schemaErrorObject
        }
      },
      [dispatch, oktaContext?.oktaAuth, onError, onSuccess],
    )

    const [formState, formAction] = useActionState(signIn, {})

    const passwordInputType = useMemo(() => {
      return isPasswordVisible ? 'text' : 'password'
    }, [isPasswordVisible])

    return (
      <form action={formAction} className="login-form">
        <label className="typog-body-small" htmlFor="email">
          Email
        </label>
        <input
          id="email"
          name="email"
          type="text"
          required
          className={formState['email'] ? 'error' : ''}
        />
        {formState['email']?.map((errorMessage) => {
          return (
            <div key={errorMessage} className="validation-error">
              {errorMessage}
            </div>
          )
        })}
        <div className="full-width">
          <label className="typog-body-small" htmlFor="password">
            Password
          </label>
          <div
            className="typog-body-small link"
            onClick={(event) => {
              event?.stopPropagation()
              setLocation(`${window.location.origin}${PATH_FORGOT_PASSWORD}`)
            }}
          >
            Forgot Password?
          </div>
        </div>
        <div className="input-with-button-icon">
          <input
            id="password"
            name="password"
            type={passwordInputType}
            required
            autoComplete="off"
            className={formState['password'] ? 'error' : ''}
          />
          <div
            onClick={() => {
              setIsPasswordVisible(!isPasswordVisible)
            }}
            className="show-password-button-sign-in"
          >
            <img
              className="show-password-icon"
              src={showPasswordIcon}
              alt="eye outline"
            />
          </div>
        </div>
        {formState['password']?.map((errorMessage) => {
          return (
            <div key={errorMessage} className="validation-error">
              {errorMessage}
            </div>
          )
        })}
        <button className="button typog-tagline">LOGIN</button>
      </form>
    )
  },
)
